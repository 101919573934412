import GenericErrorRetry from 'Client/components/Generic/ErrorPages/GenericErrorRetry';
import React from 'react';
import ErrorBoundary from '../Generic/ErrorBoundaries';
import ProjectsSlider from 'Client/components/B2B/ProjectsSlider';
import { Hero } from 'Client/components/B2B/Hero';
import PersonalService from 'Client/components/B2B/PersonalService';
import Advantages from 'Client/components/B2B/Advantages';
import { CustomerReferences } from 'Client/components/B2B/CustomerReferences';
import styled from 'styled-components';
import LineSeparator from 'Client/components/Generic/LineSeparator';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { ProjectSection } from 'Client/components/B2B/ProjectSection';
import { VideoSection } from 'Client/components/B2B/VideoSection';
import { FAQBlock } from 'Client/components/B2B/FAQ';
import { useBellaSelector } from 'Client/redux/hooks';
import { SEO } from 'Client/components/B2B/SEO';

export const StyledLineSeparator = styled(LineSeparator)`
    width: calc(100% - 40px);
    margin: 38px auto 38px;
    ${forDesktop`
        margin: 60px auto 60px;
        width: calc(100% - 70px);
    `}
`;
const B2B = () => {
    const {
        landingPageHeroBlock,
        landingPageBoxes,
        landingPageTextImageItems,
        lpsSliderSection,
        gallery,
        ourProjectsBlock,
        whatYouGetBlock,
        faqBlock,
        seoBlock,
    } = useBellaSelector(state => state.b2b);
    return (
        <ErrorBoundary boundaryName="b2b" fallbackComponent={<GenericErrorRetry />}>
            <div>
                {landingPageHeroBlock && <Hero heroBlock={landingPageHeroBlock} />}
                {landingPageTextImageItems?.length && (
                    <PersonalService landingPageText={landingPageTextImageItems[0]} />
                )}
                {landingPageBoxes && (
                    <>
                        <StyledLineSeparator />
                        <Advantages boxes={landingPageBoxes} />
                    </>
                )}
                {gallery && (
                    <>
                        <StyledLineSeparator />
                        <CustomerReferences gallery={gallery} />
                    </>
                )}

                {lpsSliderSection && (
                    <>
                        <StyledLineSeparator />
                        <ProjectSection projectSlider={lpsSliderSection} />
                    </>
                )}
                {ourProjectsBlock && (
                    <>
                        <StyledLineSeparator />
                        <ProjectsSlider ourProjectsBlock={ourProjectsBlock} />
                    </>
                )}
                {whatYouGetBlock && (
                    <>
                        <StyledLineSeparator />
                        <VideoSection whatYouGetBlock={whatYouGetBlock} />
                    </>
                )}
                {faqBlock && (
                    <>
                        <StyledLineSeparator />
                        <FAQBlock faqBlock={faqBlock} />
                    </>
                )}
                {seoBlock && (
                    <>
                        <StyledLineSeparator />
                        <SEO seoBlock={seoBlock} />
                    </>
                )}
                <StyledLineSeparator />
            </div>
        </ErrorBoundary>
    );
};

export default B2B;
